<template>
  <div>
    <Parent />
    <v-card class="mt-3">
      <div class="d-flex justify-space-between pt-5 ml-4 mb-5 align-center">
        <div class="font-18px text-52">
          新規取引先の登録
        </div>
        <div class="mr-7">
          <v-icon
            v-if="showCreateForm"
            @click="showCreateForm = !showCreateForm"
            size="12"
            >$arrowUp</v-icon
          >
          <v-icon
            v-if="!showCreateForm"
            @click="showCreateForm = !showCreateForm"
            size="12"
            >$arrowDown</v-icon
          >
        </div>
      </div>
      <div class="divider"></div>
      <validation-observer ref="observer">
        <v-form ref="form" class="pb-5" @submit.prevent="saveData">
          <SupplierForm
            v-if="showCreateForm"
            :customerName.sync="fields.business_partner_name"
            :address.sync="fields.address"
            :displayName.sync="fields.display_name"
            :homePage.sync="fields.home_page"
            :telephoneNumber.sync="fields.telephone_number"
            :personInChargeName.sync="fields.contact_person"
            :memo.sync="fields.note"
            :selectedCategory.sync="category"
          />
          <v-card-actions v-if="showCreateForm" class="justify-center mt-2">
            <v-btn color="primary" width="84px" height="24px" type="submit"
              >登録</v-btn
            >
          </v-card-actions>
        </v-form>
      </validation-observer>
    </v-card>
  </div>
</template>

<script>
import Parent from "@/views/admin/Store/Index";
import SupplierForm from "@/components/admin/partials/Supplier/SupplierForm";
export default {
  components: {
    Parent,
    SupplierForm
  },
  data() {
    return {
      showCreateForm: true,
      category: [],
      fields: {
        business_partner_name: "",
        address: "",
        display_name: "",
        home_page: "",
        telephone_number: "",
        contact_person: "",
        note: "",
        category: []
      }
    };
  },
  methods: {
    saveData() {
      this.$refs.observer.validate().then(success => {
        if (success) {
          this.fields.shop_id = this.$route.params.shop_id;
          this.fields.category = this.category.map(item => item.id);
          this.$store.dispatch("SUPPLIER_REGISTER", this.fields).then(() => {
            this.$store.dispatch("ALERT", {
              show: true,
              text: "取引先を登録しました。"
            });
            this.$router.go(-1);
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>

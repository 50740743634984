<template>
  <v-row>
    <v-col cols="12">
      <v-toolbar flat color="transparent" height="40" class="font-Roboto">
        <v-toolbar-title>
          <div class="font-20px fw-700 text-52 mr-5">
            {{ getShop.store_name }}
          </div>
        </v-toolbar-title>

        <div class="vertical-divider mr-5"></div>

        <v-row align="center">
          <v-col
            v-for="item in menus"
            :key="item.text"
            cols="auto"
            class="ml-n3"
          >
            <v-btn
              active-class="active-class"
              class="font-12px text-999 non-active-class"
              small
              text
              :to="item.to"
              :ripple="false"
            >
              <v-icon class="pr-1">{{ item.icon }}</v-icon>
              {{ item.text }}
            </v-btn>
          </v-col>
        </v-row>

        <v-spacer></v-spacer>

        <v-row class="flex-row-reverse">
          <v-col cols="auto">
            <v-btn
              small
              class="text-capitalize font-weight-regular"
              text
              @click="$router.go(-1)"
            >
              <v-icon color="secondary" class="go-back" left>$goBack</v-icon>
              {{ $t("go_back") }}
            </v-btn>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on">
                  <v-icon>$horizontalKebab</v-icon>
                </v-btn>
              </template>
              <v-card class=" d-flex flex-column justify-space-around">
                <v-list dense flat>
                  <v-list-item
                    class="mouse-pointer"
                    v-for="(option, index) in menuOptions"
                    :key="index"
                    :to="option.to ? option.to : ''"
                  >
                    <v-list-item-icon
                      ><v-icon color="#A0A0A0">{{
                        option.icon
                      }}</v-icon></v-list-item-icon
                    >
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="option.text"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
      </v-toolbar>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "index",
  computed: {
    ...mapGetters(["getShop"]),
    id() {
      return this.$route.params.id;
    }
  },
  data() {
    return {
      loading: false,
      menuOptions: [
        {
          icon: "$info",
          text: this.$t("page_edit_store_menu_dropdown_basic_information"),
          to: {
            name: "ShopsEdit",
            params: { shop_id: this.$route.params.shop_id }
          }
        },
        {
          icon: "$account",
          text: this.$t("page_edit_store_menu_dropdown_account_management"),
          to: {
            name: "SupplierList",
            params: { shop_id: this.$route.params.shop_id }
          }
        },
        {
          icon: "$sale",
          text: this.$t("page_edit_store_menu_dropdown_target_sales_settings"),
          to: {
            name: "PayableAccountsGoals",
            params: { shop_id: this.$route.params.shop_id }
          }
        },
        {
          icon: "$cost",
          text: this.$t("page_edit_store_menu_dropdown_monthly_cost_setting"),
          to: {
            name: "FixedCost",
            params: { shop_id: this.$route.params.shop_id }
          }
        },
        {
          icon: "$businessSetting",
          text: this.$t("page_edit_store_menu_dropdown_business_day_setting"),
          to: {
            name: "Business",
            params: { shop_id: this.$route.params.shop_id }
          }
        },
        {
          icon: "$inventory",
          text: this.$t("page_edit_store_menu_dropdown_inventory_settings"),
          to: {
            name: "InventoryManagement",
            params: { shop_id: this.$route.params.shop_id }
          }
        },
        {
          icon: "$mailing",
          text: this.$t("page_edit_store_menu_dropdown_mailing_list"),
          to: {
            name: "Mailing",
            params: { shop_id: this.$route.params.shop_id }
          }
        }
      ],
      menus: [
        {
          icon: "$monthlyReport",
          text: "月報",
          to: {
            name: "MonthlyReportList",
            params: { shop_id: this.$route.params.shop_id }
          }
        },
        {
          icon: "$dailyReport",
          text: "日報",
          to: {
            name: "DailyReportList",
            params: { shop_id: this.$route.params.shop_id }
          }
        },
        {
          icon: "$cashSales",
          text: "現金出納 / 小口",
          to: {
            name: "CashbookList",
            params: { shop_id: this.$route.params.shop_id }
          }
        },
        {
          icon: "$accountsPayable",
          text: "買掛",
          to: {
            name: "PayableAccountsPayable",
            params: { shop_id: this.$route.params.shop_id }
          }
        }
      ]
    };
  },
  created() {
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi() {
      this.loading = true;
      this.$store
        .dispatch("SHOP_GET", { id: this.$route.params.shop_id })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-toolbar', {
    staticClass: "font-Roboto",
    attrs: {
      "flat": "",
      "color": "transparent",
      "height": "40"
    }
  }, [_c('v-toolbar-title', [_c('div', {
    staticClass: "font-20px fw-700 text-52 mr-5"
  }, [_vm._v(" " + _vm._s(_vm.getShop.store_name) + " ")])]), _c('div', {
    staticClass: "vertical-divider mr-5"
  }), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, _vm._l(_vm.menus, function (item) {
    return _c('v-col', {
      key: item.text,
      staticClass: "ml-n3",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-btn', {
      staticClass: "font-12px text-999 non-active-class",
      attrs: {
        "active-class": "active-class",
        "small": "",
        "text": "",
        "to": item.to,
        "ripple": false
      }
    }, [_c('v-icon', {
      staticClass: "pr-1"
    }, [_vm._v(_vm._s(item.icon))]), _vm._v(" " + _vm._s(item.text) + " ")], 1)], 1);
  }), 1), _c('v-spacer'), _c('v-row', {
    staticClass: "flex-row-reverse"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "text-capitalize font-weight-regular",
    attrs: {
      "small": "",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('v-icon', {
    staticClass: "go-back",
    attrs: {
      "color": "secondary",
      "left": ""
    }
  }, [_vm._v("$goBack")]), _vm._v(" " + _vm._s(_vm.$t("go_back")) + " ")], 1), _c('v-menu', {
    attrs: {
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "text": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("$horizontalKebab")])], 1)];
      }
    }])
  }, [_c('v-card', {
    staticClass: " d-flex flex-column justify-space-around"
  }, [_c('v-list', {
    attrs: {
      "dense": "",
      "flat": ""
    }
  }, _vm._l(_vm.menuOptions, function (option, index) {
    return _c('v-list-item', {
      key: index,
      staticClass: "mouse-pointer",
      attrs: {
        "to": option.to ? option.to : ''
      }
    }, [_c('v-list-item-icon', [_c('v-icon', {
      attrs: {
        "color": "#A0A0A0"
      }
    }, [_vm._v(_vm._s(option.icon))])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(option.text)
      }
    })], 1)], 1);
  }), 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
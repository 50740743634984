var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "px-4 mr-10"
  }, [_c('v-row', {
    staticClass: "mt-8",
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "align-self": "baseline"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-end"
  }, [_c('div', {
    staticClass: " pt-3 font-12px text-9b"
  }, [_vm._v("取引先名称")]), _c('v-card', {
    staticClass: "ml-5 mt-4 rounded-sm",
    attrs: {
      "color": "#D65858",
      "flat": "",
      "height": "14px",
      "width": "29px"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-center align-center font-8px white--text"
  }, [_vm._v(" 必須 ")])])], 1)]), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "customer_name",
      "rules": "required:取引先名称 "
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('v-text-field', {
          attrs: {
            "dense": "",
            "flat": "",
            "outlined": "",
            "placeholder": "取引先名称",
            "error-messages": errors
          },
          model: {
            value: _vm.getCustomerName,
            callback: function callback($$v) {
              _vm.getCustomerName = $$v;
            },
            expression: "getCustomerName"
          }
        })];
      }
    }])
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "align-self": "baseline"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-end pt-3 font-12px text-9b"
  }, [_vm._v("住所")])]), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "flat": "",
      "outlined": "",
      "placeholder": "住所"
    },
    model: {
      value: _vm.getAddress,
      callback: function callback($$v) {
        _vm.getAddress = $$v;
      },
      expression: "getAddress"
    }
  })], 1)], 1)], 1)], 1), _c('v-row', {
    staticClass: "mt-n2",
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "align-self": "baseline"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-end"
  }, [_c('div', {
    staticClass: " pt-3 font-12px text-9b"
  }, [_vm._v("表示名")]), _c('v-card', {
    staticClass: "ml-5 mt-4 rounded-sm",
    attrs: {
      "color": "#D65858",
      "flat": "",
      "height": "14px",
      "width": "29px"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-center align-center font-8px white--text"
  }, [_vm._v(" 必須 ")])])], 1)]), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "display_name",
      "rules": "required:表示名 "
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('v-text-field', {
          attrs: {
            "dense": "",
            "flat": "",
            "outlined": "",
            "placeholder": "表示名",
            "error-messages": errors
          },
          model: {
            value: _vm.getDisplayName,
            callback: function callback($$v) {
              _vm.getDisplayName = $$v;
            },
            expression: "getDisplayName"
          }
        })];
      }
    }])
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "align-self": "baseline"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-end pt-3 font-12px text-9b"
  }, [_vm._v(" ホームページ ")])]), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "flat": "",
      "outlined": "",
      "placeholder": "ホームページURL"
    },
    model: {
      value: _vm.getHomePage,
      callback: function callback($$v) {
        _vm.getHomePage = $$v;
      },
      expression: "getHomePage"
    }
  })], 1)], 1)], 1)], 1), _c('v-row', {
    staticClass: "mt-n2",
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-end"
  }, [_c('div', {
    staticClass: " pt-3 font-12px text-9b"
  }, [_vm._v("カテゴリー")]), _c('v-card', {
    staticClass: "ml-5 mt-4 rounded-sm",
    attrs: {
      "color": "#D65858",
      "flat": "",
      "height": "14px",
      "width": "29px"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-center align-center font-8px white--text"
  }, [_vm._v(" 必須 ")])])], 1)]), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "name",
      "rules": "required:カテゴリー "
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-combobox', {
          attrs: {
            "items": _vm.getCategories,
            "chips": "",
            "color": "primary",
            "background-color": "#F8F8F8",
            "label": "",
            "multiple": "",
            "solo": "",
            "outlined": "",
            "error-messages": errors,
            "flat": "",
            "dense": ""
          },
          scopedSlots: _vm._u([{
            key: "selection",
            fn: function fn(_ref4) {
              var attrs = _ref4.attrs,
                  item = _ref4.item,
                  select = _ref4.select,
                  selected = _ref4.selected;
              return [_c('v-chip', _vm._b({
                staticClass: "chip",
                attrs: {
                  "height": "20px",
                  "color": "primary",
                  "input-value": selected,
                  "close": ""
                },
                on: {
                  "click": select,
                  "click:close": function clickClose($event) {
                    return _vm.remove(item);
                  }
                }
              }, 'v-chip', attrs, false), [_vm._v(" " + _vm._s(item.value) + " ")])];
            }
          }, {
            key: "item",
            fn: function fn(_ref5) {
              var item = _ref5.item,
                  attrs = _ref5.attrs;
              return [_c('div', _vm._b({
                staticClass: "v-list-item v-list-item--link theme--light",
                attrs: {
                  "tabindex": "0",
                  "aria-selected": "false",
                  "role": "option"
                }
              }, 'div', attrs, false), [_c('div', {
                staticClass: "v-list-item__action"
              }, [_c('div', {
                staticClass: "v-simple-checkbox"
              }, [_c('div', {
                staticClass: "v-input--selection-controls__input"
              }, [_c('i', {
                staticClass: "v-icon notranslate mdi",
                "class": [_vm.alreadySelected(item) ? 'mdi-checkbox-marked primary--text' : 'mdi-checkbox-blank-outline'],
                attrs: {
                  "aria-hidden": "true"
                }
              })])])]), _c('div', {
                staticClass: "v-list-item__content"
              }, [_c('div', {
                staticClass: "v-list-item__title"
              }, [_vm._v(_vm._s(item.value))])])])];
            }
          }], null, true),
          model: {
            value: _vm.getSelectedCategory,
            callback: function callback($$v) {
              _vm.getSelectedCategory = $$v;
            },
            expression: "getSelectedCategory"
          }
        })];
      }
    }])
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "align-self": "baseline"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-end pt-3 font-12px text-9b"
  }, [_vm._v(" 担当者名 ")])]), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "flat": "",
      "outlined": "",
      "placeholder": "担当者名"
    },
    model: {
      value: _vm.getPersonInChargeName,
      callback: function callback($$v) {
        _vm.getPersonInChargeName = $$v;
      },
      expression: "getPersonInChargeName"
    }
  })], 1)], 1)], 1)], 1), _c('v-row', {
    staticClass: "mt-n2",
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "align-self": "baseline"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-center ml-3 pt-3 font-12px text-9b"
  }, [_vm._v(" 電話番号 ")])]), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "flat": "",
      "outlined": "",
      "placeholder": "電話番号"
    },
    model: {
      value: _vm.getTelephoneNumber,
      callback: function callback($$v) {
        _vm.getTelephoneNumber = $$v;
      },
      expression: "getTelephoneNumber"
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "align-self": "baseline"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-end pt-3 font-12px text-9b"
  }, [_vm._v(" メモ ")])]), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-textarea', {
    staticClass: "input-field",
    attrs: {
      "auto-grow": "",
      "outlined": "",
      "background-color": "#F8F8F8",
      "color": "primary",
      "label": "特記事項を記入してください",
      "rows": "1",
      "dense": "",
      "solo": "",
      "width": "100%"
    },
    model: {
      value: _vm.getMemo,
      callback: function callback($$v) {
        _vm.getMemo = $$v;
      },
      expression: "getMemo"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="px-4 mr-10">
    <!--  -->
    <v-row dense class="mt-8">
      <v-col cols="12" md="6" align-self="baseline">
        <v-row>
          <v-col cols="4">
            <div class="d-flex justify-end">
              <div class=" pt-3 font-12px text-9b">取引先名称</div>
              <v-card
                color="#D65858"
                flat
                height="14px"
                width="29px"
                class="ml-5 mt-4 rounded-sm"
              >
                <div
                  class="d-flex justify-center align-center font-8px white--text"
                >
                  必須
                </div>
              </v-card>
            </div>
          </v-col>
          <v-col cols="8">
            <validation-provider
              v-slot="{ errors }"
              name="customer_name"
              rules="required:取引先名称 "
            >
              <v-text-field
                dense
                flat
                outlined
                placeholder="取引先名称"
                :error-messages="errors"
                v-model="getCustomerName"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="6" align-self="baseline">
        <v-row>
          <v-col cols="4">
            <div class="d-flex justify-end pt-3 font-12px text-9b">住所</div>
          </v-col>
          <v-col cols="8">
            <v-text-field
              dense
              flat
              outlined
              placeholder="住所"
              v-model="getAddress"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row dense class="mt-n2">
      <v-col cols="12" md="6" align-self="baseline">
        <v-row>
          <v-col cols="4">
            <div class="d-flex justify-end">
              <div class=" pt-3 font-12px text-9b">表示名</div>
              <v-card
                color="#D65858"
                flat
                height="14px"
                width="29px"
                class="ml-5 mt-4 rounded-sm"
              >
                <div
                  class="d-flex justify-center align-center font-8px white--text"
                >
                  必須
                </div>
              </v-card>
            </div>
          </v-col>
          <v-col cols="8">
            <validation-provider
              v-slot="{ errors }"
              name="display_name"
              rules="required:表示名 "
            >
              <v-text-field
                dense
                flat
                outlined
                placeholder="表示名"
                :error-messages="errors"
                v-model="getDisplayName"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" align-self="baseline">
        <v-row>
          <v-col cols="4">
            <div class="d-flex justify-end pt-3 font-12px text-9b">
              ホームページ
            </div>
          </v-col>
          <v-col cols="8">
            <v-text-field
              dense
              flat
              outlined
              placeholder="ホームページURL"
              v-model="getHomePage"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row dense class="mt-n2">
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="4">
            <div class="d-flex justify-end">
              <div class=" pt-3 font-12px text-9b">カテゴリー</div>
              <v-card
                color="#D65858"
                flat
                height="14px"
                width="29px"
                class="ml-5 mt-4 rounded-sm"
              >
                <div
                  class="d-flex justify-center align-center font-8px white--text"
                >
                  必須
                </div>
              </v-card>
            </div>
          </v-col>
          <v-col cols="8">
            <validation-provider
              v-slot="{ errors }"
              name="name"
              rules="required:カテゴリー "
            >
              <v-combobox
                v-model="getSelectedCategory"
                :items="getCategories"
                chips
                color="primary"
                background-color="#F8F8F8"
                label=""
                multiple
                solo
                outlined
                :error-messages="errors"
                flat
                dense
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    class="chip"
                    height="20px"
                    color="primary"
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click="select"
                    @click:close="remove(item)"
                  >
                    {{ item.value }}
                  </v-chip>
                </template>
                <template v-slot:item="{ item, attrs }">
                  <div
                    tabindex="0"
                    aria-selected="false"
                    v-bind="attrs"
                    role="option"
                    class="v-list-item v-list-item--link theme--light"
                  >
                    <div class="v-list-item__action">
                      <div class="v-simple-checkbox">
                        <div class="v-input--selection-controls__input">
                          <i
                            aria-hidden="true"
                            class="v-icon notranslate mdi"
                            :class="[
                              alreadySelected(item)
                                ? 'mdi-checkbox-marked primary--text'
                                : 'mdi-checkbox-blank-outline'
                            ]"
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div class="v-list-item__content">
                      <div class="v-list-item__title">{{ item.value }}</div>
                    </div>
                  </div>
                </template>
              </v-combobox>
            </validation-provider>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" align-self="baseline">
        <v-row>
          <v-col cols="4">
            <div class="d-flex justify-end pt-3 font-12px text-9b">
              担当者名
            </div>
          </v-col>
          <v-col cols="8">
            <v-text-field
              dense
              flat
              outlined
              placeholder="担当者名"
              v-model="getPersonInChargeName"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row dense class="mt-n2">
      <v-col cols="12" md="6" align-self="baseline">
        <v-row>
          <v-col cols="4">
            <div class="d-flex justify-center ml-3 pt-3 font-12px text-9b">
              電話番号
            </div>
          </v-col>
          <v-col cols="8">
            <v-text-field
              dense
              flat
              outlined
              placeholder="電話番号"
              v-model="getTelephoneNumber"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" align-self="baseline">
        <v-row>
          <v-col cols="4">
            <div class="d-flex justify-end pt-3 font-12px text-9b">
              メモ
            </div>
          </v-col>
          <v-col cols="8">
            <v-textarea
              class="input-field"
              v-model="getMemo"
              auto-grow
              outlined
              background-color="#F8F8F8"
              color="primary"
              label="特記事項を記入してください"
              rows="1"
              dense
              solo
              width="100%"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SupplierForm",
  components: {},
  props: {
    customerName: {
      type: String,
      default: "",
      required: false
    },
    address: {
      type: String,
      default: "",
      required: false
    },
    displayName: {
      type: String,
      default: "",
      required: false
    },
    homePage: {
      type: String,
      default: "",
      required: false
    },
    selectedCategory: {
      type: Array,
      default: () => [],
      required: false
    },
    personInChargeName: {
      type: String,
      default: "",
      required: false
    },
    telephoneNumber: {
      type: String,
      default: "",
      required: false
    },
    memo: {
      type: String,
      default: "",
      required: false
    }
  },
  computed: {
    ...mapGetters(["getMasterData"]),
    getCategories() {
      return this.getMasterData.partners_category.map(data => {
        return data;
      });
    },
    getCustomerName: {
      get: function() {
        return this.customerName;
      },
      set: function(value) {
        this.$emit("update:customerName", value);
      }
    },
    getAddress: {
      get: function() {
        return this.address;
      },
      set: function(value) {
        this.$emit("update:address", value);
      }
    },
    getDisplayName: {
      get: function() {
        return this.displayName;
      },
      set: function(value) {
        this.$emit("update:displayName", value);
      }
    },
    getHomePage: {
      get: function() {
        return this.homePage;
      },
      set: function(value) {
        this.$emit("update:homePage", value);
      }
    },
    getTelephoneNumber: {
      get: function() {
        return this.telephoneNumber;
      },
      set: function(value) {
        this.$emit("update:telephoneNumber", value);
      }
    },
    getPersonInChargeName: {
      get: function() {
        return this.personInChargeName;
      },
      set: function(value) {
        this.$emit("update:personInChargeName", value);
      }
    },
    getMemo: {
      get: function() {
        return this.memo;
      },
      set: function(value) {
        this.$emit("update:memo", value);
      }
    },
    getSelectedCategory: {
      get: function() {
        return this.selectedCategory;
      },
      set: function(value) {
        this.$emit("update:selectedCategory", value);
      }
    }
  },
  methods: {
    alreadySelected(item) {
      let isSelected = this.getSelectedCategory.find(
        category => category.id === item.id
      );
      if (isSelected !== undefined) {
        return true;
      } else {
        return false;
      }
    },
    remove(item) {
      let index = this.getSelectedCategory.findIndex(
        element => element.id === item.id
      );
      this.getSelectedCategory.splice(index, 1);
      this.getSelectedCategory = [...this.fields.category];
    }
  }
};
</script>
<style lang="scss" scoped>
.chip {
    height: 24px !important;
    background-color: #ffffff !important;
    color: #7367f0 !important;
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  ::v-deep
    .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
    > .v-input__control
    > .v-input__slot {
    box-shadow: none !important;
  }
  ::v-deep {
    .v-text-field {
      width: 200% !important;
      &--outlined {
        fieldset {
          background: rgba(205, 205, 205, 0.15);
          border: 0.5px solid #cdcdcd;
        }
        box-sizing: border-box;
        border-radius: 3px;
        font-size: 12px !important;
      }
  
      input {
        color: #333333 !important;
      }
  
      &__details {
        padding: 0 !important;
        margin: 0 !important;
      }
    }
  
    .v-input__slot {
      background-color: unset !important;
    }
  }
  ::v-deep textarea {
    font-size: 12px !important;
    box-shadow: none !important;
    box-shadow: none !important;
  }
  
  ::v-deep .v-label {
    color: #a0a0a0 !important;
    font-size: 12px !important;
  }
  ::v-deep {
    .error--text {
      .v-input__slot {
        background-color: rgba(214, 88, 88, 0.05) !important;
        border: 0.5px solid #d65858 !important;
      }
      fieldset {
        border: none;
      }
  
      .v-messages__message {
        font-size: 10px !important;
        color: #d65858 !important;
      }
    }
  }
</style>